var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-toolbar",
        {
          staticClass: "mx-6 mb-2 mt-2",
          attrs: { height: "50", elevation: "4", rounded: "" },
        },
        [_c("ReportMenu")],
        1
      ),
      _c("div", { attrs: { id: "reportDiv" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }