var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar-items",
    { staticClass: "w-full" },
    [
      _c(
        "v-btn",
        {
          staticClass: "nav-button text-capitalize",
          attrs: { text: "" },
          on: { click: _vm.goBack },
        },
        [
          _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-arrow-left")]),
          _vm._v(" Back to List "),
        ],
        1
      ),
      _vm.me.yellowfinId
        ? _c(
            "v-menu",
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on, attrs }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "text-capitalize",
                                attrs: { text: "" },
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [
                            _vm._v(" Reports "),
                            _c("v-icon", [_vm._v("mdi-menu-down")]),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                346342146
              ),
            },
            [
              _c(
                "v-list",
                [
                  _vm.me.is.superAdmin || _vm.me.is.transportationAdmin
                    ? _c(
                        "v-list-item",
                        { on: { click: _vm.openReportDashboard } },
                        [
                          _c("v-list-item-title", [
                            _vm._v("Open Report Builder"),
                          ]),
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-open-in-new")])],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._l(_vm.reports, function (report, index) {
                    return [
                      report.show
                        ? _c(
                            "v-list-item",
                            {
                              key: index,
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push(
                                    `${_vm.route}/report/${report.reportUUID}`
                                  )
                                },
                              },
                            },
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(report.name)),
                              ]),
                              report.icon
                                ? _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-icon", [
                                        _vm._v(_vm._s(report.icon)),
                                      ]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }